import React, { useState, useCallback } from 'react';
import useEventListener from '../hooks/useEventListener';

const Chevron = () => (
  <svg width="38" height="16" viewBox="0 0 38 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M0.425049 2.31821L1.57494 0.681824L19.4837 13.2664L36.4037 0.697272L37.5963 2.30276L19.5163 15.7337L0.425049 2.31821Z" fill="black" />
  </svg>
)

const Prompt = () => {

  const [hide, setHide] = useState(false);

  const handleScroll = useCallback(() => {
    const scrollTop = window.pageYOffset || (document.documentElement || document.body.parentNode || document.body).scrollTop;

    // If starting to scroll...
    if (scrollTop > 100 && !hide) {
      setHide(true)
    }
  }, [hide, setHide]);

  useEventListener('scroll', handleScroll);

  return (
    <div className="pabs b0 w100 txt-c" style={{
      bottom: '1rem',
      fontSize: '2rem',
      opacity: hide ? 0 : 1,
      transition: '0.5s'
    }}>
      <Chevron />
    </div>
  )
}

export default Prompt
