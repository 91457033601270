import React, { useEffect, useState, useCallback } from 'react';
import Markdown from '../components/markdown';
import Video from '../components/video';
import ProjectVideo from '../components/ProjectVideo';

const Project = (props) => {

  const {
    title,
    slug,
    videoHd,
    poster,
    hasBackground,
    description,
    services,
    externalLink
  } = props;

  return (
    <section className="project">
      {/* {!hasBackground ? (
        <Video src={videoHd} poster={poster} />
      ) : (
        <ProjectVideo videoHd={videoHd} poster={poster} />
      )} */}
      <Video src={videoHd} poster={poster} />

      <div className="project-info p1 has-underlines">

        <div className="description outerx4">
          <Markdown className="copy" field={description} />
          {services && services.map((tag, index) => (
            <span className="tag" style={{ color: tag.color }} key={`${slug}-tag-${index}`}>
              <span className="name">{tag.name}</span>
            </span>
          ))}
          {/* <div>
            {externalLink ? <a href={externalLink} target="_blank" rel="noopener noreferrer">{title}</a> : title}
          </div> */}
        </div>

      </div>
    </section>
  );
}

export default Project;
