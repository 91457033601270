import React, { useEffect, useState, useCallback } from 'react';
import { useInView } from 'react-intersection-observer';
import useVideo from '../hooks/useVideo';

const Video = ({ src, poster }) => {

  const [videoRef, play, pause] = useVideo();
  const [show, setShow] = useState(false);

  // IntersectionObserver hook...
  const [ref, inView] = useInView({
    threshold: 0.5
  });

  const handleVideoPlayer = useCallback(() => {
    if (inView) {
      // console.log('play??')
      setShow(true); // Show the container
      play() // Play the video
    } else {
      if (show) {
        pause(); // Otherwise pause the video...
      }
    }
  }, [inView, setShow, show, play, pause]);

  useEffect(handleVideoPlayer, [inView]);

  const handleTap = () => {
    play();
  }

  return (
    <div className="video-wrap" ref={ref}>
      <video src={src} ref={videoRef} onTouchEnd={handleTap} muted loop playsInline controls={false} />
    </div>
  )
}

export default Video;
