import { useRef, useCallback } from 'react';

export default function useVideo() {

  let playPromise;
  const ref = useRef(); // This needs to be a <video />
  // const [isPlaying, setIsPlaying] = useState();

  const setRef = useCallback(node => {
    if (ref.current) {
      // all good?
    }
    if (node) {
      ref.current = node;
    }
  }, [])

  const play = () => {
    playPromise = ref.current.play();

    if (playPromise !== undefined) {
      playPromise.then(_ => {
        // TODO: Set a flag that the media is playing, and you can safely pause
      })
      .catch(error => {
        //
      });
    }
  }

  const pause = () => {
    // TODO: only pause if play(Promise) has finished
    ref.current.pause();
  }

  return [setRef, play, pause];
}
