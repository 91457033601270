// Dependencies
import React, { useState, useEffect } from "react";
import { graphql } from "gatsby";
import PropTypes from "prop-types";
// Hooks
// import useWindowSize from "../hooks/useWindowSize";
// Components
import { InlineLogo } from "../components/logo";
import Layout from "../components/layout";
import Project from "../components/project";
import Prompt from "../components/prompt";
import Markdown from "../components/markdown";
import Notifications from "../components/Notifications";
import Meta from '../components/Meta';

const Chevron = () => (
  <svg width="38" height="16" viewBox="0 0 38 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M0.425049 2.31821L1.57494 0.681824L19.4837 13.2664L36.4037 0.697272L37.5963 2.30276L19.5163 15.7337L0.425049 2.31821Z" fill="black" />
  </svg>
)

// We believe in collaboration and play, and always look to create strong partnerships with like-minded people.
//
// We are fluent in both design and technology, combinined with brand awareness and good management. If you would like to work with us, get in touch.

const Portfolio = ({ pageContext, data }) => {

  const { introduction, intro, projects, notification } = data.contentfulPortfolio;
  const [height, setHeight] = useState('100vh');

  useEffect(() => {
    // Initial set...
    setHeight(window.innerHeight)

    const handleResize = () => {
      const scrollTop = window.pageYOffset || (document.documentElement || document.body.parentNode || document.body).scrollTop;
      if (scrollTop <= 0) {
        setHeight(window.innerHeight)
      }
    }
    window.addEventListener('resize', handleResize, false);
    return () => {
      window.removeEventListener('resize', handleResize, false);
    }
  }, [])

  return (
    <Layout hasName={!pageContext.isHomepage} notification={notification}>
      <Meta />

      <div className="prel fv grid-center" style={{ height }}>
        {pageContext.isHomepage ? (
          <div className="max50 mauto h1">
            <InlineLogo />
            <Markdown className="" field={intro} />
          </div>
        ) : (
          <div className="max50 mauto h1">
            <Markdown className="" field={intro} />
          </div>
        )}
        <Prompt />
      </div>

      {/* <div className="prel fv grid-center">
        <div className="max50 mauto">
          <h1>
            Selected work
            <br />
            <Chevron />
          </h1>
        </div>
      </div> */}

      <div className="projects">
        {projects.map((node, i) => {
          return (
            <Project {...node} key={`${node.slug}-${i}`} />
          )
        })}
      </div>


    </Layout>
  )
}

Portfolio.propTypes = {
  data: PropTypes.object.isRequired
}

export default Portfolio

export const ContentfulProjectFragment = graphql`
  fragment ContentfulProjectFragment on ContentfulProject {
    contentful_id
    title
    slug
    videoHd
    videoSd
    poster
    hasBackground
    description {
      childMarkdownRemark {
        html
      }
    }
    externalLink
    services {
      name
      color
    }
  }
`

export const pageQuery = graphql`
  query PortfolioTemplateQuery($slug: String!) {
    contentfulPortfolio(slug: {eq: $slug}) {
      introduction
      intro {
        childMarkdownRemark {
          html
        }
      }
      projects {
        ...ContentfulProjectFragment
      }
			notification {
				copy {
					childMarkdownRemark {
						html
					}
				}
				internalLink
				linkLabel
			}
    }
  }
`
